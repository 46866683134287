"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
function Contact() {
    var _a = (0, react_1.useState)({
        name: "",
        email: "",
        message: "",
    }), formData = _a[0], setFormData = _a[1];
    var _b = (0, react_1.useState)({
        name: "",
        email: "",
        message: "",
    }), formErrors = _b[0], setFormErrors = _b[1];
    var _c = (0, react_1.useState)(false), formSubmitted = _c[0], setFormSubmitted = _c[1];
    var _d = (0, react_1.useState)(false), formFinished = _d[0], setFormFinished = _d[1];
    var handleFormInput = function (_a) {
        var target = _a.target;
        return setFormData(function (prevFormData) {
            var _a;
            return (__assign(__assign({}, prevFormData), (_a = {}, _a[target.name] = target.value, _a)));
        });
    };
    var handleFormSubmit = function (e) {
        setFormErrors(validate(formData));
        setFormSubmitted(true);
        e.preventDefault();
    };
    var validate = function (formData) {
        var errors = {};
        if (!formData.name)
            errors.name = "Name required";
        if (!formData.email)
            errors.email = "Email required";
        if (!formData.message)
            errors.message = "Message required";
        return errors;
    };
    var encode = function (data) {
        return Object.keys(data)
            .map(function (key) { return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]); })
            .join("&");
    };
    (0, react_1.useEffect)(function () {
        if (Object.keys(formErrors).length === 0 && formSubmitted) {
            fetch("/", {
                method: "POST",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body: encode(__assign({ "form-name": "contact--form" }, formData))
            })
                .then(function () { return setFormSubmitted(false); })
                .then(function () { return setFormFinished(true); })
                .then(function () { return setFormData({ name: "", email: "", message: "" }); })
                .catch(function (error) { return alert(error); });
        }
    }, [formErrors, formData, formSubmitted]);
    return (react_1.default.createElement("section", { className: "contact", id: "contact" },
        react_1.default.createElement("h1", { className: 'contact--title' }, "GET IN TOUCH"),
        react_1.default.createElement("p", { className: 'contact--subtitle' },
            "Feel free to contact me by ",
            react_1.default.createElement("a", { href: 'mailto: kesselring.skylar@gmail.com', className: 'contact--subtitle-email' }, "email"),
            " or with the form below."),
        formFinished
            ?
                react_1.default.createElement("div", { className: 'contact--form' }, "succesfully submitted, thank you!")
            :
                react_1.default.createElement("form", { className: 'contact--form', name: 'contact--form', onSubmit: handleFormSubmit },
                    react_1.default.createElement("div", { className: 'contact--form-top' },
                        react_1.default.createElement("div", null,
                            (formErrors === null || formErrors === void 0 ? void 0 : formErrors.name) ? react_1.default.createElement("p", { className: 'error- -name' }, "Name required!") : react_1.default.createElement("p", { className: 'error- -name' }),
                            react_1.default.createElement("input", { className: 'contact--form-input -name', placeholder: 'Your name', name: 'name', type: 'text', value: formData.name, onChange: handleFormInput })),
                        react_1.default.createElement("div", null,
                            (formErrors === null || formErrors === void 0 ? void 0 : formErrors.email) ? react_1.default.createElement("p", { className: 'error- -email' }, "Email required!") : react_1.default.createElement("p", { className: 'error- -email' }),
                            react_1.default.createElement("input", { className: 'contact--form-input -email', placeholder: 'Your email', name: 'email', type: 'email', value: formData.email, onChange: handleFormInput }))),
                    react_1.default.createElement("div", null,
                        (formErrors === null || formErrors === void 0 ? void 0 : formErrors.message) ? react_1.default.createElement("p", { className: 'error- -message' }, "Message required!") : react_1.default.createElement("p", { className: 'error- -message' }),
                        react_1.default.createElement("textarea", { className: 'contact--form-input -message', placeholder: 'Your message', name: 'message', value: formData.message, onChange: handleFormInput })),
                    react_1.default.createElement("button", { className: 'contact--form-button' }, "SEND MESSAGE"))));
}
exports.default = Contact;
